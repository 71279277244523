.modal-content-register {
    background: #70991d !important;
    box-shadow: 0 0 14px rgba(245, 191, 40, .5);
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 20px 10px;
    pointer-events: auto;
    position: relative;
    width: 20rem;
  }
  
  .btn_Model {
      padding: 10px 20px;
      border-radius: 2.4rem;
      display: inline-block;
      cursor: pointer;
      transition: .2s linear;
      color: #fff !important;
      text-decoration: none;
      font: 400 1rem "Mukta Vaani", sans-serif;
      border-width: 0;
      margin-bottom: 0.5rem;
      margin-top: 10px;
      /* width: 100%; */
      max-width: 13rem;
      box-shadow: 2px 3px 2px rgba(166, 165, 165, .16);
      background: #198754;
  }