.page-content {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.nftHeading h2 {
  color: white;
  font-size: 18px;
  margin: 18px 0;
}

@media screen and (min-width: 1400px) {
  .page-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 600px) and (max-width: 10000px) {
  .popular_nfts img {
    max-height: 280px;
  }
}

.popular_nfts img {
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.page-wrapper {
  height: 100%;
  margin-top: 85px;
  margin-bottom: 3rem;
  margin-left: 0px;
  /* background-color: #354724; */
  /* background: linear-gradient(45deg, #000000, #161616db),
    url(../../assets/images/img1.jpg);
  height: max-content; */
}

.popular_nfts {
  position: relative;
}

.popular_nfts .nftTimeItem {
  top: 125px;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
  background-color: #2553117d !important; 
  /* box-shadow: 0 0 2px 3px #23a001; */
  border: 2px solid #487006 !important;
  padding: 29px 19px;
  margin: 14px;
  width: 100%;
  /* height: max-content; */
  height: 280px;
  border-radius: 4px;
}

.popular_nfts .nftTimeItem:hover {
 box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}
.priceChart_text{
 
  position: relative;
  top: 55px;
  left: -4px;
  color: #fff;
  font-size: 20px;;

}
.priceChart1{
  background-image: url(../../assets/images/timer.png);
  background-size: cover;
  background-position: center;
  width: 141px;
  height: 141px;
}
.profileSection:hover {
  box-shadow:inset 0px -1px 12px 3px #23a001 !important;
}

.SellersItem:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}

.popular_nfts .nftTimeItem h2,
.popular_nfts .nftTimeItem h1 {
  font-size: 18px;
  margin-bottom: 0;
  color: white;
}

.popular_nfts .nftTimeItem p {
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  color: white;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain {
  zoom: 90%;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart {
  margin: 0 auto;
  position: relative;
  zoom: 95%;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: antiquewhite;
}

.navbar-toggler {
  background-color: white !important;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  color: rgba(255, 255, 255, 0.7);
  font-family: "sans-serif";
  font-style: normal;
  user-select: none;
  -webkit-touch-callout: none;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart::after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background: linear-gradient(153.09deg, #450202 -14.22%, #120a31 91.92%);
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart .bar {
  position: absolute;
  border-image: linear-gradient(153.09deg, #23a001 -14.22%, #3f4e03 91.92%);
  border-image-slice: 1;
  width: 117px;
  height: 119px;
  clip: rect(0, 0.8em, 1em, 0);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 0.08em solid transparent;
  background: linear-gradient(153.09deg, #23a001 -14.22%, #23a001 91.92%)
    border-box;
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.bgcolor {
  /* background: linear-gradient(45deg, #247b0e, #c5f53d); */
  border-radius: 10px;
  padding: 10px;
  color: black;
  font-weight: 500 !important;
}

.popular_nfts .nftTimeItem button {
  background-color: #487e31;
    color: #fff;
    border: 1px solid #e0e533;
    padding: 5px 12px;
    font-size: 14px;
    width: 100%;
    margin-top: 12px;
}

.mt30hsff2 {
  margin-top: 50px;
}

.SellersItem {
  display: flex;
  justify-content: center;
  /* box-shadow: 0 0px 27px -10px rgb(64 237 71); */
  border-radius: 5px;
  padding: 9px;
  border: 2px solid #487006 !important;
  transition: all 0.3s ease-in-out;
  background-color: #2553117d !important;
}

.SellersItem .SellersText {
  padding-top: 10px;
  width: 100%;
  color: white;
}

.SellersItem .SellersText h2 {
  font-size: 15px;
  margin-bottom: 7px;
  text-align: center;
}

.SellersText .timetext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.SellersText .timetext span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SellersText .timetext span img {
  filter: invert(1);
}

.Img_profile {
  position: relative;
  top: 50px;
}
:where(.css-dev-only-do-not-override-1drr2mu).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: #1b5d1f !important;
}

.img_p {
  width: 84px;
  /* border-radius: 50%; */
  /* box-shadow: 0 0 0px 1px #23a001; */
}

.zackItem {
  margin-bottom: 11px;
}

.zackItem .profileSection {
  color: white;
  border-radius: 5px;
  padding: 68px 15px 58px 15px;
  border: 2px solid #487006 !important;
  text-align: center;
  background-color: #2553117d !important;
}

.zackItem .profileSection h2 {
  font-size: 16px;
}

.timetextimg span img {
  filter: invert(1);
}

.binerincome span {
  width: 60px;
  height: 60px;
}

.cardscards {
  overflow-y: auto;
  height: 438px;
}

.maincard {
  border: 2px solid #23a001;
  transition: all 0.3s ease-in-out;
}
.p-0 {
  padding: 0;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.mycard {
  background-color:#375b2878 !important;
  border-radius: 10px;
}
.he {
  height: 120px;
}
.popular_nfts {
  position: relative;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  color: white;
  background-color: #2553117d !important;
}

.nftteam2nd {
  transform: unset !important;
  text-align: unset !important;
  position: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  padding: unset !important;
  margin: unset !important;
  width: unset !important;
  border-radius: unset !important;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

/* .mycard2 {
  background-color: #283d1f !important;
} */

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.myteamcard {
  border:2px solid #487006 !important;
  transition: all 0.3s ease-in-out;
  background-color: #2553117d !important;
  height: 100%;
  overflow-y: auto;
  height: 284px;
  color: white;
}

.myteamcard .card-header {
  border-bottom: 1px dashed rgb(121, 124, 19);
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.bgmain {
  background-color:#4ca424 !important
}

.myteamcard .cardteam {
  background-color: #258e37;
  margin-top: 5px;
  color: white;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.myteamcard .cardteam .btn1 {
  background-color: #283d1f !important;
  width: 100%;
  height: 100%;
  padding: 17px 10px !important;
  color: white !important;
}

.listcricle .circle-small {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 50px;
  margin: 0 20px;
}

.crdgjaiq {
  border-radius: 5px !important;
  border: 2px solid #487006 !important;
}

.card-body:hover {
  box-shadow:inset 0px -1px 12px 3px #23a001 !important;
}

.jashja {
  border: 1px solid #40b345;
  transition: all 0.3s ease-in-out;
  padding: 5px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
  border: 1px solid #40b345;
}

.listcricle .circle-small .text {
      /* position: absolute; */
      width: 85px;
      /* top: 14px; */
      font-size: 13px;
      border-radius: 5px;
      border: 1px solid #e0e533;
      text-align: center;
      font-weight: 400;
      line-height: 40px;
      color: white;
      background: #487e31;
}

.mt30hsffTT {
  margin-top: 70px;
}

.neggnarg {
  margin-top: -91px;
}

.listcricle .circle-small svg {
  width: 80px;
  height: 80px;
}

.listcricle .circle-small .bg {
  fill: none;
  stroke-width: 6px;
  stroke: #343035;
}

.listcricle .bg {
  min-height: 100vh;
  padding-bottom: 60px;
  padding-top: 80px;
  /* background: url(../img/back-desktop.5b69385c.jpg) no-repeat 50%; */
  background-size: cover;
  background-attachment: fixed;
}

.myteamcard:hover {
  box-shadow: inset 0px -1px 12px 3px rgb(121, 124, 19) !important;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.TableMain {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* margin-top: 65px; */
  border-collapse: collapse;
  width: 100%;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 800px) {
  .resSeb {
    margin-top: 160px;
  }

  .mjaqhsduqs {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .neggnarg {
    margin-top: 0;
  }

  .mt30hsffhxn {
    margin-top: 25px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
}

.bar {
  border: 0 !important;
}
.ksjhajq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 370px) {
  .startedsection .copytext {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 550px) {
  .popular_nfts .nftTimeItem button {
    font-size: 10px;
    width: 47% !important;
  }

  .leffttf {
    margin-right: 5px;
  }

  .mt30hsff {
    margin-top: 15px;
  }

  .resSeb {
    margin-top: 35px;
  }

  .popular_nfts .nftTimeItem {
    left: 46%;
  }

  .bgcolor {
    font-size: 13px;
  }

  .reassa {
    height: 450px !important;
    top: 210px !important;
  }

  .frstemghxn {
    height: 280px;
  }

  .heightdgt {
    height: 452px !important;
  }
}

@media (min-width: 551px) and (max-width: 950px) {
  .resSeb {
    margin-top: 40px;
  }
}

@media (min-width: 951px) and (max-width: 1215px) {
  .mt30hsff {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .bgcolor {
    font-size: 10px;
  }
}

@media (min-width: 1216px) and (max-width: 10000px) {
  .mt30hsff {
    margin-top: 10px;
  }

  .mt30hsffhxn {
    margin-top: 30px;
  }
}


td {
  padding: 0.5rem;
  color: white;
  font-size: 1.1rem;
  text-align: center;
}