.topbar {
  position: fixed;
  top: 0;
  left: 0px;
  right: 0;
  background: #487e31;
  height: 60px;
  /* background-image: linear-gradient(90deg, rgb(11, 22, 149), rgb(189, 39, 197)); */
  border-bottom: 1px solid rgba(224, 229, 51);
  z-index: 10;
}

.brdbtm {
  border-bottom: 1px solid rgba(224, 229, 51);
  height: 52px;
}

.topbar .navbar {
  width: 100%;
  justify-content: space-between;
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
}

.topbar-logo-header {
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 30px 0 0;
  height: 60px;
  border-right: 1px solid rgba(224, 229, 51);
}

.topbar-logo-header img {
  height: 52px;
}

.Id_Profile {
  display: flex;
}

.six {
  margin-bottom: 0px !important;
  color: #fff;
  padding-top: 11px;
}

.id_Inactive {
  height: 21px !important;
  width: 21px !important;
  position: relative;
  top: -2px;
}

.dahHeaderemg {
  width: 40px !important;
  height: 40px !important;
  top: -2px;
}

.dropbtn {
  background-color: transparent;
  color: white;
  font-size: 16px;
  border: none;
  padding: 20px 0px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  top: 53px;
    border-radius: 5px;
  position: absolute;
 background-color: #56a434;
    min-width: 169px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  font-size: 12px;
  padding: 10px 8px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: white;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

@media screen and (max-width: 550px) {
  .dropdown-content a {
    color: black;
    padding: 3px 7px 3px 7px;
    text-decoration: none;
    display: block;
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .topbar {
    display: none !important;
  }

  .brdbtm {
    top: 0 !important;
  }

  .navbar > .container {
    justify-content: space-between !important;
  }

  .page-wrapper {
    margin-top: 70px;
    margin-bottom: 6rem;
  }
}

@media (min-width: 1200px) {
  .navbar_brandDash {
    display: none;
  }
}

a {
  font-weight: initial !important;
}

@media screen and (max-width: 1199px) {
  .lenk {
    margin-right: 20px;
    color: white;
    border-bottom: 1px solid rgba(224, 229, 51);
    width: 100%;
    padding: 5px 10px;
  }
}

@media (min-width: 1200px) and (max-width: 10000px) {
  .dropbtn,
  .lenk {
    font-size: 12px;
  }
}
